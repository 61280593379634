import { FETCH_TIPOS_NIF, GET_TIPOS_NIF } from "../actions/types.js";

const initialState = {
  tipos_nif: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TIPOS_NIF:
      return {
        ...state,
        tipos_nif: [],
        isLoading: true,
      };
    case GET_TIPOS_NIF:
      return {
        ...state,
        tipos_nif: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
