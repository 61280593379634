import {
  GET_TIPOS_EXPEDIENTES,
  GET_TIPOS_EXPEDIENTES_CAMPOS,
  GET_EXPEDIENTES,
  FETCH_TIPOS_EXPEDIENTES,
  FETCH_TIPOS_EXPEDIENTES_CAMPOS,
  FETCH_EXPEDIENTES,
} from "../actions/types";

const initialState = {
  tipos_expedientes: [],
  tipos_expedientes_campos: [],
  expedientes: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_TIPOS_EXPEDIENTES:
      return {
        ...state,
        isLoading: true,
        tipos_expedientes: [],
      };
    case GET_TIPOS_EXPEDIENTES:
      return {
        ...state,
        isLoading: false,
        tipos_expedientes: action.payload,
      };
    case FETCH_TIPOS_EXPEDIENTES_CAMPOS:
      return {
        ...state,
        isLoading: true,
        tipos_expedientes_campos: [],
      };
    case GET_TIPOS_EXPEDIENTES_CAMPOS:
      return {
        ...state,
        isLoading: false,
        tipos_expedientes_campos: action.payload,
      };
    case FETCH_EXPEDIENTES:
      return {
        ...state,
        isLoading: true,
        expedientes: [],
      };
    case GET_EXPEDIENTES:
      return {
        ...state,
        isLoading: false,
        expedientes: action.payload,
      };

    default:
      return state;
  }
}
