import  axios from "../axiosConfig";

import {
  FETCH_LISTAS_COMPRA,
  GET_LISTAS_COMPRA,
  DELETE_LISTA_COMPRA,
  CREATE_LISTA_COMPRA,
  SET_VER_LISTA,
  DELETE_LINEA_LISTA_COMPRA
} from "./types";

import { tokenConfig } from "./auth";

import { createMessage, returnErrors } from "./messages";

//////////////////////////////////////
//////////////CABECERA///////////////
/////////////////////////////////////

export const getListasCompra = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_LISTAS_COMPRA,
  });
  axios
    .get(`/listasCompra/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_LISTAS_COMPRA,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getLineasListaCompra = (codigo_lista) => (dispatch, getState) => {
  let config = tokenConfig(getState);
  config.params = {
    lista_compra: codigo_lista,
  };

  axios
    .get(`/listasCompraLineas/`, config)
    .then((res) => {
      let aux = {
        codigo_lista: codigo_lista,
        lineas: res.data,
      };

      dispatch({
        type: SET_VER_LISTA,
        payload: aux,
      });
    })
    .catch((err) => console.log(err));
};

// ELIMINAR LA LISTA
export const eliminarLista = (codigo_lista) => (dispatch, getState) => {
  axios
    .delete(
      `/listasCompra/${codigo_lista}/`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch(createMessage({ listaEliminada: "Lista Eliminada" }));
      dispatch({
        type: DELETE_LISTA_COMPRA,
        payload: codigo_lista,
      });
    })
    .catch((err) => console.log(err));
};

export const crearLista = (_nombre_lista) => (dispatch, getState) => {
  const body = { nombre_lista: _nombre_lista };

  axios
    .post(
      `/listasCompra/`,
      body,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch(createMessage({ listaCreada: "Lista creada" }));
      dispatch({
        type: CREATE_LISTA_COMPRA,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

//////////////////////////////////////
////////////////LINEAS///////////////
/////////////////////////////////////

// // DELETE LINEA
export const eliminarLineaLista = (id) => (dispatch, getState) => {
  axios
    .delete(
      `/listasCompraLineas/${id}/`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch(createMessage({ lineaListaEliminada: "Linea Eliminada" }));
      dispatch({
        type: DELETE_LINEA_LISTA_COMPRA,
        payload: id,
      });
    })
    .catch((err) => console.log(err));
};

// // CREATE LINEA
export const postLineaLista = (linea) => (dispatch, getState) => {
  const body = JSON.stringify(linea);

  axios
    .post(
      "/listasCompraLineas/",
      body,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch(
        createMessage({
          lineaAnadida: "Se ha añadido el artículo a su lista",
        })
      );
    })
    .catch((err) => {
      dispatch(
        returnErrors(
          "No se ha podido añadir el artículo a su lista en este momento.",
          err.response.status
        )
      );
      console.log(err);
    });
};

// // CREATE N LINEAS
export const postLineasLista = (_lineas) => (dispatch, getState) => {
  const body = { lineas: _lineas };

  axios
    .post(
      "/listasCompraLineas/createmany/",
      body,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch(
        createMessage({
          lineaAnadida: "Se han añadido los artículos a la lista",
        })
      );
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        returnErrors(
          "No se han podido añadir los artículos a su lista en este momento.",
          err.response.status
        )
      );
      console.log(err);
    });
};
