import {
  FETCH_AGRUP_HIJOS,
  GET_AGRUP_HIJOS,
  FETCH_AGRUP_COND,
  GET_AGRUP_COND,
} from "../actions/types.js";

const initialState = {
  cargando_hijos: false,
  cargando_condiciones: false,
  hijos: [],
  condiciones: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_AGRUP_HIJOS:
      return {
        ...state,
        cargando_hijos: true,
      };
    case GET_AGRUP_HIJOS:
      return {
        ...state,
        hijos: action.payload,
        cargando_hijos: false,
      };
    case FETCH_AGRUP_COND:
      return {
        ...state,
        cargando_condiciones: true,
      };
    case GET_AGRUP_COND:
      return {
        ...state,
        condiciones: action.payload,
        cargando_condiciones: false,
      };
    default:
      return state;
  }
}
