import {
  FETCH_DOM_ENVIO,
  GET_DOM_ENVIO,
  FETCH_ESTADO,
  GET_ESTADO,
  FETCH_PROVINCIA,
  GET_PROVINCIA,
  SET_DIRECCION,
} from "../actions/types.js";

const initialState = {
  domicilios_envio: [],
  domicilio: {},
  estado: {},
  provincia: {},
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_DOM_ENVIO:
      return {
        ...state,
        domicilios_envio: [],
        domicilio: {},
        isLoading: true,
      };
    case GET_DOM_ENVIO:
      return {
        ...state,
        domicilios_envio: action.payload,
        isLoading: false,
      };
    case FETCH_ESTADO:
      return {
        ...state,
        estado: {},
        isLoading: true,
      };
    case GET_ESTADO:
      return {
        ...state,
        estado: action.payload,
        isLoading: false,
      };
    case FETCH_PROVINCIA:
      return {
        ...state,
        provincia: {},
        isLoading: true,
      };
    case GET_PROVINCIA:
      return {
        ...state,
        provincia: action.payload,
        isLoading: false,
      };
    case SET_DIRECCION:
      return {
        ...state,
        domicilio: action.payload,
      };
    default:
      return state;
  }
}
