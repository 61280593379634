import {
  FETCH_ALMACENES,
  GET_ALMACENES,
  SET_ALMACEN_RECOGIDA,
} from "../actions/types.js";

const initialState = {
  almacenes: [],
  almacen: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ALMACENES:
      return {
        ...state,
        almacenes: [],
        almacen:{},
        isLoading: true,
      };
    case GET_ALMACENES:
      return {
        ...state,
        almacenes: action.payload,
        isLoading: false,
      };
    case SET_ALMACEN_RECOGIDA:
      return {
        ...state,
        almacen: action.payload,
      };
    default:
      return state;
  }
}
