import { GET_PROVINCIAS, FETCH_PROVINCIAS } from "../actions/types.js";

const initialState = {
  provincias: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROVINCIAS:
      return {
        ...state,
        provincias: [],
      };
    case GET_PROVINCIAS:
      return {
        ...state,
        provincias: action.payload,
      };
    default:
      return state;
  }
}
