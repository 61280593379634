import axios from "../axiosConfig";
import { tokenConfig } from "./auth";

import { GET_PRECIO, GET_PRECIO_SUCCESS, GET_PRECIO_FAIL } from "./types";

// GET ARTICULO
export const getPrecio = (
  codigo_articulo,
  hash_invitado = "",
  cantidad_pedida = 1
) => (dispatch, getState) => {
  dispatch({
    type: GET_PRECIO,
    codigo_articulo: codigo_articulo,
  });
  axios
    .get(
      `/precio/?codigo_articulo=${codigo_articulo}&inv=${hash_invitado}&cant=${cantidad_pedida}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_PRECIO_SUCCESS,
        codigo_articulo: codigo_articulo,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log("Precio:", err.response.data);
      dispatch({
        type: GET_PRECIO_FAIL,
        codigo_articulo: codigo_articulo,
      });
    });
};
