import {
  GET_GALERIA,
  GET_GALERIA_SUCCESS,
  GET_GALERIA_FAIL,
} from "../actions/types.js";

const initialState = {
  galeria: {},
};

export default function (state = initialState, action) {
  let lista_galerias;
  switch (action.type) {
    case GET_GALERIA:
      lista_galerias = state.galeria;

      lista_galerias[action.codigo_articulo] = {
        data: [],
        isLoading: true,
        error: false,
      };
      return {
        ...state,
        galeria: { ...state.galeria, ...lista_galerias },
      };

    case GET_GALERIA_FAIL:
      lista_galerias = state.galeria;

      lista_galerias[action.codigo_articulo].data = [];
      lista_galerias[action.codigo_articulo].isLoading = false;
      lista_galerias[action.codigo_articulo].error = true;
      return {
        ...state,
        galeria: { ...state.galeria, ...lista_galerias },
      };

    case GET_GALERIA_SUCCESS:
      lista_galerias = state.galeria;

      lista_galerias[action.codigo_articulo].data = action.payload;
      lista_galerias[action.codigo_articulo].isLoading = false;
      lista_galerias[action.codigo_articulo].error = false;
      return {
        ...state,
        galeria: { ...state.galeria, ...lista_galerias },
      };
    default:
      return state;
  }
}
