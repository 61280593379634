import {
  FETCH_PEDIDOS_ANTERIORES,
  GET_PEDIDOS_ANTERIORES,
  SET_VER_PEDIDO,
} from "../actions/types";

const initialState = {
  pedidos_anteriores: [],
  pedido_anterior_detalle: 0,
  lineas_pedido_anterior_detalle: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PEDIDOS_ANTERIORES:
      return {
        ...state,
        pedidos_anteriores: [],
        pedido_anterior_detalle: 0,
        lineas_pedido_anterior_detalle: [],
        isLoading: true,
      };

    case SET_VER_PEDIDO:
      let pedido = state.pedidos_anteriores.filter(
        (ped) => ped.id_pedido === parseInt(action.payload)
      );

      return {
        ...state,
        pedido_anterior_detalle: parseInt(action.payload),
        lineas_pedido_anterior_detalle:
          pedido[0].lineas !== undefined ? pedido[0].lineas : [],
      };

    case GET_PEDIDOS_ANTERIORES:
      return {
        ...state,
        isLoading: false,
        pedidos_anteriores: action.payload,
      };

    default:
      return state;
  }
}
