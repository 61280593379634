import {
  FETCH_MODULAR,
  GET_MODULAR,
  FETCH_MODULAR_DET,
  GET_MODULAR_DET,
} from "../actions/types.js";

const initialState = {
  modulos: [],
  componentes: [],
  cargando: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_MODULAR:
      return {
        ...state,
        //modular: [], NO SE LIMPIA EL ESTADO PARA MEJORAR EL RENDIMIENTO
        cargando: true,
      };
    case FETCH_MODULAR_DET:
      return {
        ...state,
        //componentes: [], NO SE LIMPIA EL ESTADO PARA MEJORAR EL RENDIMIENTO
        cargando: true,
      };
    case GET_MODULAR:
      return {
        ...state,
        modulos: [...state.modulos, ...action.payload],
        cargando: false,
      };
    case GET_MODULAR_DET:
      let componentes_nuevo = JSON.parse(JSON.stringify(state.componentes));

      for (let componente of action.payload.componentes) {
        const indice = componentes_nuevo.findIndex(
          (c) => c.id === componente.id
        );

        if (indice !== -1) {
          componentes_nuevo[indice] = componente;
        } else {
          componentes_nuevo.push(componente);
        }
      }

      return {
        ...state,
        componentes: componentes_nuevo,
        cargando: false,
      };
    default:
      return state;
  }
}
