import {
  GET_COMUNIDADES_AUTONOMAS,
  FETCH_COMUNIDADES_AUTONOMAS,
} from "../actions/types.js";

const initialState = {
  comunidades_autonomas: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_COMUNIDADES_AUTONOMAS:
      return {
        ...state,
        comunidades_autonomas: [],
      };
    case GET_COMUNIDADES_AUTONOMAS:
      return {
        ...state,
        comunidades_autonomas: action.payload,
      };
    default:
      return state;
  }
}
