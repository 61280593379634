import {
  FETCH_ARTICULO,
  GET_ARTICULO,
  FETCH_ARTICULOS_DOC,
  GET_ARTICULOS_DOC,
  FETCH_ARTICULOS_AUX,
  GET_ARTICULOS_AUX,
  GET_STOCK_ARTICULO_FICHA,
  FETCH_CODIGOS_BARRAS,
  GET_CODIGOS_BARRAS,
} from "../actions/types.js";

const initialState = {
  articulo: {},
  articulos_doc: [],
  articulos_aux: [],
  codigos_barras: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTICULO:
      return {
        ...state,
        articulo: {},
        isLoading: true,
      };
    case GET_ARTICULO:
      return {
        ...state,
        isLoading: false,
        articulo: action.payload,
      };
    case FETCH_ARTICULOS_DOC:
      return {
        ...state,
        articulos_doc: [],
        isLoading: true,
      };
    case GET_ARTICULOS_DOC:
      return {
        ...state,
        articulos_doc: action.payload,
        isLoading: false,
      };
    case FETCH_ARTICULOS_AUX:
      return {
        ...state,
        articulos_aux: [],
        isLoading: true,
      };
    case GET_ARTICULOS_AUX:
      return {
        ...state,
        articulos_aux: action.payload,
        isLoading: false,
      };
    case GET_STOCK_ARTICULO_FICHA:
      let articulo_nuevo = JSON.parse(JSON.stringify(state.articulo));
      articulo_nuevo["info_stock"] = action.payload;

      return {
        ...state,
        articulo: articulo_nuevo,
        isLoading: false,
      };
    case FETCH_CODIGOS_BARRAS:
      return {
        ...state,
        codigos_barras: [],
        isLoading: true,
      };
    case GET_CODIGOS_BARRAS:
      return {
        ...state,
        isLoading: false,
        codigos_barras: action.payload,
      };
    default:
      return state;
  }
}
