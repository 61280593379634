import {
  FETCH_FILTROS,
  GET_FILTROS,
  LIMPIAR_FILTROS,
  UPDATE_FILTROS,
  REMOVE_FILTRO,
} from "../actions/types.js";

const initialState = {
  filtros: [],
  filtros_aplicados: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_FILTROS:
      return {
        ...state,
        filtros: [],
        filtros_aplicados: [],
        isLoading: true,
      };
    case GET_FILTROS:
      return {
        ...state,
        isLoading: false,
        filtros: action.payload["filtros"],
      };
    case LIMPIAR_FILTROS:
      return {
        ...state,
        isLoading: false,
        filtros_aplicados: [],
        familia_seleccionada: "",
      };
    case UPDATE_FILTROS:
      return {
        ...state,
        isLoading: false,
        filtros_aplicados: [...state.filtros_aplicados, action.payload],
      };
    case REMOVE_FILTRO:
      let findPos = (elem) =>
        elem.estadistico === action.payload.estadistico &&
        elem.familia === action.payload.familia;

      let aux = state.filtros_aplicados.slice();
      let pos = aux.findIndex(findPos);
      aux.splice(pos, 1);

      return {
        ...state,
        isLoading: false,
        filtros_aplicados: aux,
      };
    default:
      return state;
  }
}
