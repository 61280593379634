import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  TOKEN_FAIL,
  TOKEN_REFRESH,
  TOKEN_REFRESHING,
  SEND_CAMBIO_PASSWORD,
  CAMBIO_PASSWORD_SUCCESS,
  CAMBIO_PASSWORD_FAIL,
  RECOVER_PASS,
  RECOVER_PASS_FAIL,
  RECOVER_PASS_SUCCESS,
  COMPROBAR_TOKEN,
  COMPROBAR_TOKEN_SUCCESS,
  COMPROBAR_TOKEN_FAIL,
  REINICIAR_PASSWORD,
  REINICIAR_PASSWORD_SUCCESS,
  REINICIAR_PASSWORD_FAIL,
  ENVIAR_REGISTRO,
  ENVIAR_REGISTRO_SUCCESS,
  ENVIAR_REGISTRO_FAIL,
  BORRA_HASH
} from "../actions/types";

import jwtDecode from "jwt-decode";

const initialState = {
  token: localStorage.getItem("token"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  portal: null,
  promesa: null,
  token_info: null,
  irAInicio: false,
  hash: localStorage.getItem("hash"),
  invitado: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
    case TOKEN_REFRESHING:
    case SEND_CAMBIO_PASSWORD:
    case RECOVER_PASS:
    case REINICIAR_PASSWORD:
    case ENVIAR_REGISTRO:
      return {
        ...state,
        isLoading: true,
      };
    case COMPROBAR_TOKEN:
    case ENVIAR_REGISTRO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        irAInicio: false,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case COMPROBAR_TOKEN_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        user: action.payload,
      };
    case TOKEN_REFRESH:
      localStorage.setItem("token", action.payload.access);
      return {
        ...state,
        isLoading: false,
        token: action.payload.access,
        token_info: jwtDecode(action.payload.access),
      };
    case LOGIN_SUCCESS:
    case REGISTER_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("refresh", action.payload.refresh);
      localStorage.setItem("hash", action.payload.hash);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false,
        token_info: jwtDecode(action.payload.token),
        hash: action.payload.hash,
        invitado: action.payload.invitado,
      };
    case TOKEN_FAIL:
      if (!state.isLoading) {
        localStorage.removeItem("token");
        return {
          ...state,
          token: null,
          user: null,
          isAuthenticated: false,
          isLoading: false,
        };
      } else {
        return state;
      }

    case LOGOUT_SUCCESS:
      if (action.borra_hash) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        localStorage.removeItem("hash");
        return {
          ...state,
          token: null,
          refresh: null,
          token_info: null,
          user: null,
          isAuthenticated: false,
          isLoading: false,
          hash: null,
        };
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh");
        return {
          ...state,
          token: null,
          refresh: null,
          token_info: null,
          user: null,
          isAuthenticated: false,
          isLoading: false,
        };
      }

    case AUTH_ERROR:
    case LOGIN_FAIL:
    case REGISTER_FAIL:
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      return {
        ...state,
        token: null,
        refresh: null,
        token_info: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case CAMBIO_PASSWORD_SUCCESS:
    case CAMBIO_PASSWORD_FAIL:
    case RECOVER_PASS_SUCCESS:
    case RECOVER_PASS_FAIL:
    case COMPROBAR_TOKEN_FAIL:
    case REINICIAR_PASSWORD_FAIL:
    case ENVIAR_REGISTRO_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case REINICIAR_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        irAInicio: true,
      };
    case BORRA_HASH:
      localStorage.removeItem("hash");
      return {
        ...state,
        hash: null
      };
    default:
      return state;
  }
}
