import {
  GET_PORTAL,
  GET_PORTAL_SUCCESS,
  GET_PORTAL_FAIL,
  GET_MENUS,
  GET_MENUS_SUCCESS,
  GET_MENUS_FAIL,
  SET_ACEPTAR_COOKIES,
} from "../actions/types.js";

const initialState = {
  portal: {},
  logo: null,
  menus: [],
  cookies: {
    acepta_cookies:
      localStorage.getItem("acepta_cookies") === "true"
        ? true
        : localStorage.getItem("acepta_cookies") === "false"
        ? false
        : null,
    fecha_consentimiento:
      localStorage.getItem("fecha_consentimiento") !== null
        ? Number.parseInt(localStorage.getItem("fecha_consentimiento"))
        : null,
    basicas: true,
    publicitarias:
      localStorage.getItem("cookies_publicitarias") === "true"
        ? true
        : localStorage.getItem("cookies_publicitarias") === "false"
        ? false
        : null,
    analiticas:
      localStorage.getItem("cookies_analiticas") === "true"
        ? true
        : localStorage.getItem("cookies_analiticas") === "false"
        ? false
        : null,
  },
  isLoadingPortal: false,
  isLoadingMenus: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PORTAL:
      return {
        ...state,
        isLoadingPortal: true,
      };
    case GET_PORTAL_SUCCESS:
      return {
        ...state,
        portal: action.payload,
        isLoadingPortal: false,
      };
    case GET_PORTAL_FAIL:
      return {
        ...state,
        portal: {},
        isLoadingPortal: false,
      };
    case GET_MENUS:
      return {
        ...state,
        isLoadingMenus: true,
      };
    case GET_MENUS_SUCCESS:
      return {
        ...state,
        menus: action.payload,
        isLoadingMenus: false,
      };
    case GET_MENUS_FAIL:
      return {
        ...state,
        menus: [],
        isLoadingMenus: false,
      };
    case SET_ACEPTAR_COOKIES:
      localStorage.setItem("acepta_cookies", action.payload.acepta_cookies);
      localStorage.setItem(
        "fecha_consentimiento",
        action.payload.fecha_consentimiento
      );
      localStorage.setItem(
        "cookies_publicitarias",
        action.payload.cookies_publicitarias
      );
      localStorage.setItem(
        "cookies_analiticas",
        action.payload.cookies_analiticas
      );

      let nuevas_cookies = JSON.parse(JSON.stringify(state.cookies));

      nuevas_cookies.acepta_cookies = action.payload.acepta_cookies;
      nuevas_cookies.fecha_consentimiento = action.payload.fecha_consentimiento;
      nuevas_cookies.cookies_publicitarias =
        action.payload.cookies_publicitarias;
      nuevas_cookies.cookies_analiticas = action.payload.cookies_analiticas;

      return {
        ...state,
        cookies: nuevas_cookies,
      };
    default:
      return state;
  }
}
