import { Component } from "react";
import { connect } from "react-redux";

export class SimboloMoneda extends Component {
  state = {
    EUR: "€",
    BOB: "$",
    DOP: "$",
    GBP: "£",
    USD: "$",
    COP: "$",
  };

  render() {
    if (
      this.state[this.props.moneda] !== undefined &&
      this.state[this.props.moneda] !== ""
    ) {
      return this.state[this.props.moneda];
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(SimboloMoneda);
